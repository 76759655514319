// src/components/HuntingGroundMaps.js

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Alert,
  Button,
  Modal,
  InputGroup,
  FormControl,
  ListGroup,
} from 'react-bootstrap';
import { useLoadScript } from '@react-google-maps/api';
import { AuthContext } from '../../context/auth/AuthContext';
import ErrorBoundary from './ErrorBoundary';
import MapComponent from './MapComponent';
import CustomLayers from './CustomLayers';
import LayerSelection from './LayerSelection';
import { debounce } from 'lodash';

const HuntingGroundMaps = () => {
  const [selectedLayers, setSelectedLayers] = useState({});
  const [loadingLayers, setLoadingLayers] = useState({});
  const [layerErrors, setLayerErrors] = useState({});
  const { isSubscribed } = useContext(AuthContext);

  // State for Bus Stop Type Filters
  const [busStopTypeFilters, setBusStopTypeFilters] = useState([]);
  const busStopTypes = [
    '1 - Premium',
    '4 - Regular',
    'Interchange',
    'Busway Station',
    'Railway Station',
  ];

  // State for Road Hierarchy Type Filters
  const [roadHierarchyTypeFilters, setRoadHierarchyTypeFilters] = useState([]);
  const roadHierarchyTypes = [
    { code: 'INF_AR', label: 'Arterial Roads (INF_AR)' },
    { code: 'INF_DR', label: 'District Roads (INF_DR)' },
    { code: 'INF_SR', label: 'Suburban Roads (INF_SR)' },
    { code: 'INF_MW', label: 'Motorways (INF_MW)' },
  ];

  const [customLayers, setCustomLayers] = useState([]);
  const [isDrawingMode, setIsDrawingMode] = useState(false);

  // State for Service Selection Modal
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceRatings, setServiceRatings] = useState({}); // { serviceName: minRating }
  const [services, setServices] = useState([
    'Gym',
    'Shopping Centre',
    'School',
    // Add more default services as needed
  ]);

  // State to trigger the service search in MapComponent
  const [serviceSearchTrigger, setServiceSearchTrigger] = useState(0);

  // State to store best areas from MapComponent
  const [bestAreas, setBestAreas] = useState([]);

  // Reference to the map instance
  const mapRef = useRef(null); // New mapRef

  // State to control visibility of other markers
  const [showOtherMarkers, setShowOtherMarkers] = useState(true);

  const FIREBASE_FUNCTION_URL =
    'https://us-central1-lunar8platform.cloudfunctions.net/api';

  const layerUrls = useMemo(
    () => ({
      'Bus Stops': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/brisbane-bus-stops`,
        type: 'api',
        cacheKey: 'busStops',
      },
      'Road Hierarchy': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/road-hierarchy`,
        type: 'api',
        cacheKey: 'roadHierarchy',
      },
      'Train Stations': {
        url: `${FIREBASE_FUNCTION_URL}/proxy/brisbane-train-stations`,
        type: 'api',
        cacheKey: 'trainStations',
      },
      // Add more layers as needed
    }),
    [FIREBASE_FUNCTION_URL]
  );

  const availableLayers = useMemo(() => {
    if (isSubscribed) {
      return layerUrls;
    } else {
      return {
        'Bus Stops': layerUrls['Bus Stops'],
        'Road Hierarchy': layerUrls['Road Hierarchy'],
        'Train Stations': layerUrls['Train Stations'],
        // Include only free layers or those accessible to non-subscribed users
      };
    }
  }, [isSubscribed, layerUrls]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['drawing', 'geometry', 'places'],
  });

  const onLoad = useCallback((map) => {
    console.log('Google Map loaded');
    mapRef.current = map; // Store map instance in mapRef
  }, []);

  const handleLayerToggle = useCallback((layerName) => {
    console.log(`Toggling layer: ${layerName}`);
    setSelectedLayers((prev) => ({
      ...prev,
      [layerName]: !prev[layerName],
    }));

    // Clear previous errors when toggling
    setLayerErrors((prev) => ({
      ...prev,
      [layerName]: null,
    }));
  }, []);

  const handleBusStopTypeToggle = (type) => {
    setBusStopTypeFilters((prev) => {
      if (prev.includes(type)) {
        return prev.filter((t) => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleRoadHierarchyTypeToggle = (typeCode) => {
    setRoadHierarchyTypeFilters((prev) => {
      if (prev.includes(typeCode)) {
        return prev.filter((t) => t !== typeCode);
      } else {
        return [...prev, typeCode];
      }
    });
  };

  const handleToggleVisibility = (index) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index ? { ...layer, visible: !layer.visible } : layer
      )
    );
  };

  const handleDeleteLayer = (index) => {
    setCustomLayers((prevLayers) => {
      const layerToRemove = prevLayers[index];
      // Remove overlay from map
      if (layerToRemove.type === 'marker') {
        layerToRemove.overlay.setMap(null);
      } else {
        layerToRemove.overlay.setMap(null);
        if (layerToRemove.radiusLabel) {
          layerToRemove.radiusLabel.setMap(null);
        }
      }
      return prevLayers.filter((_, i) => i !== index);
    });
  };

  /**
   * Handle updating the style (strokeColor, fillColor, opacity) of a custom layer
   */
  const handleUpdateStyle = (index, newStyle) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index
          ? {
              ...layer,
              strokeColor: newStyle.strokeColor,
              fillColor: newStyle.fillColor,
              opacity: newStyle.opacity,
            }
          : layer
      )
    );
  };

  /**
   * Handle updating the layer name
   */
  const handleUpdateLayerName = (index, newName) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index ? { ...layer, name: newName } : layer
      )
    );
  };

  /**
   * Handle updating the layer radius
   */
  const handleUpdateLayerRadius = (index, newRadius) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) => {
        if (i === index && layer.type === 'circle') {
          layer.overlay.setRadius(newRadius);
          return {
            ...layer,
            radius: newRadius,
          };
        }
        return layer;
      })
    );
  };

  /**
   * Handle toggling the "No Fill" option
   */
  const handleToggleNoFill = (index) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) => {
        if (i === index) {
          return {
            ...layer,
            noFill: !layer.noFill,
          };
        }
        return layer;
      })
    );
  };

  /**
   * Handle updating the icon URL for a marker layer
   */
  const handleUpdateLayerIcon = (index, newIconUrl) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) =>
        i === index ? { ...layer, iconUrl: newIconUrl } : layer
      )
    );
  };

  /**
   * Handle updating the marker color
   */
  const handleUpdateMarkerColor = (index, newColor) => {
    setCustomLayers((prevLayers) =>
      prevLayers.map((layer, i) => {
        if (i === index && layer.type === 'marker') {
          return {
            ...layer,
            markerColor: newColor,
          };
        }
        return layer;
      })
    );
  };

  // Cache for fetched data with timestamps
  const fetchedDataCache = useRef({});

  // Memoized function to transform API data to GeoJSON
  const transformDataToGeoJSON = useCallback((data) => {
    // Since data is already in GeoJSON format, we can return it directly
    if (data.type === 'FeatureCollection') {
      return data;
    }

    // For other data formats, handle accordingly
    // ... existing logic ...

    return null;
  }, []);

  // Handle opening the Service Selection Modal
  const handleOpenServiceModal = () => {
    setShowServiceModal(true);
  };

  // Handle closing the Service Selection Modal
  const handleCloseServiceModal = () => {
    setShowServiceModal(false);
  };

  // Handle selecting services
  const handleServiceToggle = (service) => {
    setSelectedServices((prev) => {
      if (prev.includes(service)) {
        const newSelected = prev.filter((s) => s !== service);
        const newRatings = { ...serviceRatings };
        delete newRatings[service];
        setServiceRatings(newRatings);
        return newSelected;
      } else {
        // Limit the number of selectable services to 5
        if (prev.length >= 5) return prev; // Prevent adding more
        return [...prev, service];
      }
    });
  };

  // Handle minimum rating change
  const handleServiceRatingChange = (service, rating) => {
    setServiceRatings((prev) => ({
      ...prev,
      [service]: rating,
    }));
  };

  // Handle adding a new custom service
  const [newService, setNewService] = useState('');
  const handleAddCustomService = () => {
    if (newService.trim() !== '' && !services.includes(newService.trim())) {
      setServices((prev) => [...prev, newService.trim()]);
      setNewService('');
    }
  };

  // Debounced Handle "Go" button click to prevent rapid triggers
  const debouncedHandleServiceSearch = useCallback(
    debounce(() => {
      // Close the modal
      setShowServiceModal(false);

      // Perform the search and computation
      setServiceSearchTrigger((prev) => prev + 1); // Trigger the effect in MapComponent
    }, 500), // 500ms debounce delay
    []
  );

  // Handle "Go" button click
  const handleServiceSearch = () => {
    // Reset best areas
    setBestAreas([]);
    // Trigger the debounced function
    debouncedHandleServiceSearch();
  };

  // Handle clicking on a best area to pan map and show info window
  const handleBestAreaClick = (area) => {
    if (area && area.center && window.google && window.google.maps && mapRef.current) {
      const map = mapRef.current;
      map.panTo(area.center);
      map.setZoom(15);

      // Close any existing info windows
      if (area.infoWindow) {
        area.infoWindow.close();
      }

      // Create content for the info window
      let contentString = `<div><strong>Area with Radius: ${Math.round(area.radius)} m</strong><br/><ul>`;
      area.locations.forEach((loc) => {
        const place = loc.place;
        contentString += `<li><a href="https://www.google.com/maps/place/?q=place_id:${place.place_id}" target="_blank">${place.name}</a> - Rating: ${place.rating || 'N/A'} - Address: ${place.vicinity || place.formatted_address || 'N/A'}</li>`;
      });
      contentString += '</ul></div>';

      // Create and open the info window at the area's center
      const infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
        position: area.center,
      });
      infoWindow.open(map);

      // Store the infoWindow reference to close it later if needed
      area.infoWindow = infoWindow;
    }
  };

  // State to store references to info windows (to close them when needed)
  const infoWindowRefs = useRef([]);

  if (loadError) {
    console.error('Error loading Google Maps script:', loadError);
    return (
      <Alert variant="danger">
        Error loading Google Maps. Please try again later.
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <Container fluid>
        <Row>
          <Col
            md={2}
            className="border p-3"
            style={{ height: '100vh', overflowY: 'auto' }}
          >
            {/* Layer Selection */}
            <LayerSelection
              availableLayers={availableLayers}
              selectedLayers={selectedLayers}
              onLayerToggle={handleLayerToggle}
              loadingLayers={loadingLayers}
              layerErrors={layerErrors}
              busStopTypes={busStopTypes}
              busStopTypeFilters={busStopTypeFilters}
              onBusStopTypeToggle={handleBusStopTypeToggle}
              roadHierarchyTypes={roadHierarchyTypes}
              roadHierarchyTypeFilters={roadHierarchyTypeFilters}
              onRoadHierarchyTypeToggle={handleRoadHierarchyTypeToggle}
            />

            {/* Show loading indicators for layers */}
            {Object.keys(loadingLayers).some((layer) => loadingLayers[layer]) && (
              <div className="mt-2 d-flex align-items-center">
                <Spinner animation="border" size="sm" className="me-2" />
                Loading layers...
              </div>
            )}
            {/* Show error messages for layers */}
            {Object.keys(layerErrors).map(
              (layerName) =>
                layerErrors[layerName] && (
                  <Alert key={layerName} variant="danger" className="mt-2">
                    Error loading {layerName}: {layerErrors[layerName]}
                  </Alert>
                )
            )}
            <hr />
            {/* Toggle Drawing Mode */}
            <Button
              variant="primary"
              onClick={() => setIsDrawingMode((prev) => !prev)}
              className="mb-2"
              block
            >
              {isDrawingMode ? 'Cancel Drawing' : 'Add Custom Layer'}
            </Button>
            {/* Custom Layers */}
            <CustomLayers
              customLayers={customLayers}
              onToggleVisibility={handleToggleVisibility}
              onDeleteLayer={handleDeleteLayer}
              onUpdateStyle={handleUpdateStyle}
              onUpdateLayerName={handleUpdateLayerName}
              onUpdateLayerRadius={handleUpdateLayerRadius}
              onToggleNoFill={handleToggleNoFill}
              onUpdateLayerIcon={handleUpdateLayerIcon}
              onUpdateMarkerColor={handleUpdateMarkerColor}
            />
            <hr />
            {/* Find Best Areas Button */}
            <Button
              variant="success"
              onClick={handleOpenServiceModal}
              className="mb-2"
              block
            >
              Find Best Areas
            </Button>

            {/* Hide Other Markers Button */}
            <Button
              variant="secondary"
              onClick={() => setShowOtherMarkers((prev) => !prev)}
              className="mb-2"
              block
            >
              {showOtherMarkers ? 'Hide' : 'Show'} Other Markers
            </Button>

            {/* Best Areas List */}
            {bestAreas.length > 0 && (
              <>
                <h5>Recommended Areas</h5>
                <ListGroup>
                  {bestAreas.map((area, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      onClick={() => handleBestAreaClick(area)}
                    >
                      Area {index + 1}: Radius {Math.round(area.radius)} m
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Col>
          <Col md={10}>
            {!isLoaded ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100vh' }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <MapComponent
                isLoaded={isLoaded}
                loadError={loadError}
                onLoad={onLoad}
                selectedLayers={selectedLayers}
                availableLayers={availableLayers}
                busStopTypeFilters={busStopTypeFilters}
                roadHierarchyTypeFilters={roadHierarchyTypeFilters}
                setLoadingLayers={setLoadingLayers}
                setLayerErrors={setLayerErrors}
                transformDataToGeoJSON={transformDataToGeoJSON}
                customLayers={customLayers}
                setCustomLayers={setCustomLayers}
                isDrawingMode={isDrawingMode}
                setIsDrawingMode={setIsDrawingMode}
                fetchedDataCache={fetchedDataCache}
                selectedServices={selectedServices}
                serviceRatings={serviceRatings}
                serviceSearchTrigger={serviceSearchTrigger}
                setBestAreas={setBestAreas}
                mapRef={mapRef}
                showOtherMarkers={showOtherMarkers}
              />
            )}
          </Col>
        </Row>

        {/* Service Selection Modal */}
        <Modal show={showServiceModal} onHide={handleCloseServiceModal}>
          <Modal.Header closeButton>
            <Modal.Title>Select Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {services.map((service) => (
                <div key={service} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label={service}
                    checked={selectedServices.includes(service)}
                    onChange={() => handleServiceToggle(service)}
                    disabled={
                      !selectedServices.includes(service) &&
                      selectedServices.length >= 5
                    } // Limit to 5 services
                  />
                  {selectedServices.includes(service) && (
                    <Form.Group
                      controlId={`rating-${service}`}
                      className="mt-1"
                    >
                      <Form.Label>Minimum Rating:</Form.Label>
                      <Form.Control
                        type="number"
                        min="1"
                        max="5"
                        step="0.1"
                        value={serviceRatings[service] || ''}
                        onChange={(e) =>
                          handleServiceRatingChange(
                            service,
                            parseFloat(e.target.value)
                          )
                        }
                        placeholder="Enter minimum rating (e.g., 4.0)"
                      />
                    </Form.Group>
                  )}
                </div>
              ))}
              {/* Add custom service */}
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Add custom service"
                  value={newService}
                  onChange={(e) => setNewService(e.target.value)}
                />
                <Button
                  variant="outline-secondary"
                  onClick={handleAddCustomService}
                >
                  Add
                </Button>
              </InputGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseServiceModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleServiceSearch}>
              Go
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </ErrorBoundary>
  );
};

export default React.memo(HuntingGroundMaps);
