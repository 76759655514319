// src/components/LayerSelection.js

import React from 'react';
import { Form, Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * LayerSelection Component
 * 
 * Renders checkboxes for available layers and their respective filters.
 * Allows users to toggle layers on/off and apply specific filters.
 */
const LayerSelection = ({
  availableLayers,
  selectedLayers,
  onLayerToggle,
  loadingLayers,
  layerErrors,
  busStopTypes,
  busStopTypeFilters,
  onBusStopTypeToggle,
  railStationStatuses, // New prop
  railStationStatusFilters, // New prop
  onRailStationStatusToggle, // New prop
}) => {
  return (
    <>
      <h4>Select Layers</h4>
      <Form>
        {Object.keys(availableLayers).map((layerName) => (
          <div key={layerName} className="mb-3">
            <Form.Check
              type="checkbox"
              label={layerName}
              checked={!!selectedLayers[layerName]}
              onChange={() => onLayerToggle(layerName)}
              disabled={loadingLayers[layerName]}
            />
            {/* Bus Stops Filters */}
            {layerName === 'Bus Stops' && selectedLayers[layerName] && (
              <div style={{ marginLeft: '20px' }}>
                <strong>Bus Stop Types:</strong>
                {busStopTypes.map((type) => (
                  <Form.Check
                    key={type}
                    type="checkbox"
                    label={type}
                    checked={busStopTypeFilters.includes(type)}
                    onChange={() => onBusStopTypeToggle(type)}
                  />
                ))}
              </div>
            )}
            {/* Railway Stations Filters */}
            {layerName === 'Railway Stations' && selectedLayers[layerName] && (
              <div style={{ marginLeft: '20px' }}>
                <strong>Railway Station Status:</strong>
                {railStationStatuses.map((status) => (
                  <Form.Check
                    key={status}
                    type="checkbox"
                    label={status}
                    checked={railStationStatusFilters.includes(status)}
                    onChange={() => onRailStationStatusToggle(status)}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </Form>
      {/* Show loading indicators for layers */}
      {Object.keys(loadingLayers).some((layer) => loadingLayers[layer]) && (
        <div className="mt-2 d-flex align-items-center">
          <Spinner animation="border" size="sm" className="me-2" />
          Loading layers...
        </div>
      )}
      {/* Show error messages for layers */}
      {Object.keys(layerErrors).map(
        (layerName) =>
          layerErrors[layerName] && (
            <Alert key={layerName} variant="danger" className="mt-2">
              Error loading {layerName}: {layerErrors[layerName]}
            </Alert>
          )
      )}
    </>
  );
};

LayerSelection.propTypes = {
  availableLayers: PropTypes.object.isRequired,
  selectedLayers: PropTypes.object.isRequired,
  onLayerToggle: PropTypes.func.isRequired,
  loadingLayers: PropTypes.object.isRequired,
  layerErrors: PropTypes.object.isRequired,
  busStopTypes: PropTypes.array.isRequired,
  busStopTypeFilters: PropTypes.array.isRequired,
  onBusStopTypeToggle: PropTypes.func.isRequired,
  railStationStatuses: PropTypes.array.isRequired, // New prop type
  railStationStatusFilters: PropTypes.array.isRequired, // New prop type
  onRailStationStatusToggle: PropTypes.func.isRequired, // New prop type
};

export default LayerSelection;
