// src/components/CustomLayersManager.js

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomLayersManager = ({
  mapRef,
  customLayers,
  setCustomLayers,
}) => {
  /**
   * Manage visibility and styles of custom layers whenever customLayers state changes
   */
  useEffect(() => {
    customLayers.forEach((layer) => {
      if (layer.type === 'marker') {
        // Set visibility
        layer.overlay.setVisible(layer.visible);

        // Update icon if iconUrl is set
        if (layer.iconUrl) {
          layer.overlay.setIcon({
            url: layer.iconUrl,
          });
        } else if (layer.markerColor) {
          // Use markerColor to generate a colored marker
          const svgMarker = {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: layer.markerColor,
            fillOpacity: 1,
            strokeColor: 'white',
            strokeWeight: 1,
            scale: 6,
          };
          layer.overlay.setIcon(svgMarker);
        } else {
          layer.overlay.setIcon(null); // default icon
        }
      } else {
        // Set visibility
        layer.overlay.setMap(layer.visible ? mapRef.current : null);
        if (layer.radiusLabel) {
          layer.radiusLabel.setMap(layer.visible ? mapRef.current : null);
        }

        // Update styles
        const options = {
          fillColor: layer.noFill ? 'transparent' : layer.fillColor,
          fillOpacity: layer.noFill ? 0 : layer.opacity,
          strokeColor: layer.strokeColor,
          strokeOpacity: layer.opacity,
          editable: true, // Ensure the overlay remains editable
        };
        layer.overlay.setOptions(options);
      }
    });
  }, [customLayers, mapRef]);

  /**
   * Live update of distance number during dragging
   */
  useEffect(() => {
    customLayers.forEach((layer) => {
      if (layer.type === 'circle' && layer.overlay) {
        const overlay = layer.overlay;
        const label = layer.radiusLabel;

        const updateLabel = () => {
          const updatedRadius = overlay.getRadius();
          const updatedCenter = overlay.getCenter();
          const updatedPosition = window.google.maps.geometry.spherical.computeOffset(
            updatedCenter,
            updatedRadius,
            45 // 45 degrees from north (northeast)
          );
          label.setPosition(updatedPosition);
          label.setLabel({
            text: `${Math.round(updatedRadius)} m`,
            color: 'black',
            fontSize: '14px',
            fontWeight: 'bold',
          });
          // Update the radius in the customLayers state
          setCustomLayers((prevLayers) =>
            prevLayers.map((l) =>
              l.overlay === overlay ? { ...l, radius: updatedRadius } : l
            )
          );
        };

        // Remove previous listeners to avoid duplicates
        window.google.maps.event.clearListeners(overlay, 'radius_changed');
        window.google.maps.event.clearListeners(overlay, 'center_changed');

        // Add listeners for live updates
        overlay.addListener('radius_changed', updateLabel);
        overlay.addListener('center_changed', updateLabel);
      }
    });
  }, [customLayers, setCustomLayers]);

  return null; // This component does not render anything
};

CustomLayersManager.propTypes = {
  mapRef: PropTypes.object.isRequired,
  customLayers: PropTypes.array.isRequired,
  setCustomLayers: PropTypes.func.isRequired,
};

export default CustomLayersManager;
