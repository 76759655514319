// src/components/MapComponent.js

import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, Autocomplete } from '@react-google-maps/api';
import { Spinner, Alert, FormControl, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import LayersManager from './LayersManager';
import DrawingManagerComponent from './DrawingManagerComponent';
import CustomLayersManager from './CustomLayersManager';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: -27.4698, // Brisbane latitude
  lng: 153.0251, // Brisbane longitude
};

const MapComponent = ({
  isLoaded,
  loadError,
  onLoad,
  selectedLayers,
  availableLayers,
  busStopTypeFilters,
  roadHierarchyTypeFilters,
  setLoadingLayers,
  setLayerErrors,
  transformDataToGeoJSON,
  customLayers,
  setCustomLayers,
  isDrawingMode,
  setIsDrawingMode,
  fetchedDataCache,
  selectedServices,
  serviceRatings,
  serviceSearchTrigger,
  setBestAreas,
  mapRef,
  showOtherMarkers,
}) => {
  const serviceMarkersRef = useRef([]);
  const bestAreasRef = useRef([]);
  const markersInBestAreasRef = useRef(new Set());

  // State for Autocomplete
  const [autocomplete, setAutocomplete] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchMarker, setSearchMarker] = useState(null);

  const onAutocompleteLoad = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        mapRef.current.panTo(location);
        mapRef.current.setZoom(15);

        // Place a marker at the searched location
        if (searchMarker) {
          searchMarker.setMap(null);
        }
        const marker = new window.google.maps.Marker({
          map: mapRef.current,
          position: location,
          title: place.formatted_address || place.name,
        });
        setSearchMarker(marker);
      } else {
        alert('No details available for input: \'' + place.name + '\'');
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  // Handle input change to detect clearing of input
  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value === '') {
      // Input was cleared
      if (searchMarker) {
        searchMarker.setMap(null);
        setSearchMarker(null);
      }
    }
  };

  useEffect(() => {
    const performSearchAndComputation = () => {
      if (
        serviceSearchTrigger > 0 &&
        selectedServices.length > 0 &&
        mapRef.current
      ) {
        // Perform the search and computation
        const map = mapRef.current;
        const bounds = map.getBounds();

        if (!bounds) {
          alert('Map bounds not available. Please try again.');
          return;
        }

        const service = new window.google.maps.places.PlacesService(map);
        const promises = [];

        // Clear previous markers and areas
        serviceMarkersRef.current.forEach((markerObj) => markerObj.marker.setMap(null));
        serviceMarkersRef.current = [];
        bestAreasRef.current.forEach((areaObj) => {
          areaObj.circle.setMap(null);
          if (areaObj.label) {
            areaObj.label.setMap(null);
          }
        });
        bestAreasRef.current = [];
        markersInBestAreasRef.current = new Set();

        // Fetch places for each selected service
        const MAX_RESULTS_PER_SERVICE_TYPE = 60; // Maximum results per service type

        selectedServices.forEach((serviceType) => {
          const request = {
            bounds: bounds,
            keyword: serviceType,
          };

          promises.push(
            getAllResultsForServiceType(service, request, MAX_RESULTS_PER_SERVICE_TYPE)
              .then((results) => {
                // Filter results based on rating and service type
                const minRating = serviceRatings[serviceType] || 0;
                const filteredResults = results.filter(
                  (place) =>
                    (place.rating || 0) >= minRating &&
                    isPlaceMatchingServiceType(place, serviceType)
                );
                return { serviceType, results: filteredResults };
              })
              .catch((status) => {
                console.warn(`PlacesServiceStatus not OK for ${serviceType}: ${status}`);
                return { serviceType, results: [] };
              })
          );
        });

        Promise.all(promises).then((servicesResults) => {
          // Create a map of service type to their locations
          const serviceTypeLocations = {};
          const markerIdToPlace = {}; // Map of marker IDs to place data

          servicesResults.forEach(({ serviceType, results }) => {
            serviceTypeLocations[serviceType] = results.map((place) => {
              const marker = new window.google.maps.Marker({
                map: map,
                position: place.geometry.location,
                title: place.name,
                icon: {
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                },
              });

              const infoWindow = new window.google.maps.InfoWindow({
                content: `
                  <div>
                    <strong>${place.name}</strong><br/>
                    Rating: ${place.rating || 'N/A'}<br/>
                    Address: ${place.vicinity || place.formatted_address || 'N/A'}
                  </div>
                `,
              });

              marker.addListener('click', () => {
                infoWindow.open(map, marker);
              });

              const markerId = window.google.maps.event.addListener(marker, 'click', () => {});
              marker.__gm_id = markerId; // Assign a unique ID to the marker

              serviceMarkersRef.current.push({ marker, infoWindow, serviceType });

              // Map marker ID to place
              markerIdToPlace[markerId] = { place, serviceType, marker };

              // Store the place data
              return { location: place.geometry.location, markerId, place };
            });
          });

          // Compute the best areas
          const bestAreas = computeBestAreas(serviceTypeLocations, 20);

          // Highlight markers in best areas
          const markersInBestAreas = new Set();

          bestAreas.forEach((area, index) => {
            // Markers in this area
            area.locations.forEach((loc) => {
              markersInBestAreas.add(loc.markerId);
            });

            // Display the best areas on the map
            const circle = new window.google.maps.Circle({
              map: map,
              center: area.center,
              radius: area.radius,
              fillColor: '#FF0000',
              fillOpacity: 0.2,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            });

            // Add a label to the circle showing the radius
            const labelPosition = window.google.maps.geometry.spherical.computeOffset(
              area.center,
              area.radius,
              0 // 0 degrees from north
            );

            const label = new window.google.maps.Marker({
              position: labelPosition,
              map: map,
              label: {
                text: `Area ${index + 1}: ${Math.round(area.radius)} m`,
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 0, // Hide the default marker icon
              },
              draggable: false,
              clickable: false,
              zIndex: 1000,
            });

            bestAreasRef.current.push({ circle, label });
          });

          // Store markersInBestAreas in ref
          markersInBestAreasRef.current = markersInBestAreas;

          // Update the parent component with best areas
          setBestAreas(bestAreas);

          if (bestAreas.length > 0) {
            // Adjust the map to show all areas
            const combinedBounds = new window.google.maps.LatLngBounds();
            bestAreas.forEach((area) => {
              combinedBounds.union(area.circleBounds);
            });
            map.fitBounds(combinedBounds);
          } else {
            alert('No areas found where all selected services are in close proximity.');
          }

          // Update marker icons and visibility
          serviceMarkersRef.current.forEach(({ marker }) => {
            if (markersInBestAreas.has(marker.__gm_id)) {
              marker.setIcon({
                url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
              });
              marker.setVisible(true); // Ensure best area markers are visible
            } else {
              marker.setIcon({
                url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
              });
              marker.setVisible(showOtherMarkers);
            }
          });
        });
      }
    };
    performSearchAndComputation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSearchTrigger]);

  useEffect(() => {
    // Update visibility of markers when showOtherMarkers changes
    serviceMarkersRef.current.forEach(({ marker }) => {
      if (markersInBestAreasRef.current.has(marker.__gm_id)) {
        marker.setVisible(true); // Best area markers always visible
      } else {
        marker.setVisible(showOtherMarkers);
      }
    });
  }, [showOtherMarkers]);

  // Helper function to fetch all results with pagination
  const getAllResultsForServiceType = (service, request, maxResults) => {
    return new Promise((resolve, reject) => {
      let results = [];
      const fetchNextPage = (pagination) => {
        if (pagination && pagination.hasNextPage) {
          setTimeout(() => {
            pagination.nextPage();
          }, 2000); // Delay to prevent OVER_QUERY_LIMIT error
        } else {
          resolve(results);
        }
      };
      const callback = (newResults, status, pagination) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          results = results.concat(newResults);
          if (results.length >= maxResults) {
            results = results.slice(0, maxResults);
            resolve(results);
          } else {
            fetchNextPage(pagination);
          }
        } else if (status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          resolve(results);
        } else {
          reject(status);
        }
      };
      service.nearbySearch(request, callback);
    });
  };

  // Helper function to check if place matches the service type
  const isPlaceMatchingServiceType = (place, serviceType) => {
    const placeName = place.name.toLowerCase();
    const serviceTypeLower = serviceType.toLowerCase();
    const serviceWords = serviceTypeLower.split(' ');
    return serviceWords.every((word) => placeName.includes(word));
  };

  // Function to compute the best areas with non-overlapping constraint
  const computeBestAreas = (serviceTypeLocations, topN) => {
    let serviceTypes = Object.keys(serviceTypeLocations);

    // If any service type has no locations, we cannot find areas
    if (serviceTypes.some((type) => serviceTypeLocations[type].length === 0)) {
      return [];
    }

    // Limit the number of locations per service type to reduce computation
    const MAX_LOCATIONS_PER_SERVICE = 10;
    serviceTypes.forEach((type) => {
      serviceTypeLocations[type] = serviceTypeLocations[type].slice(
        0,
        MAX_LOCATIONS_PER_SERVICE
      );
    });

    // Generate all combinations of one location per service type
    const combinations = cartesianProduct(
      Object.values(serviceTypeLocations)
    );

    // Compute total distance for each combination
    let areas = combinations.map((locations) => {
      const points = locations.map((loc) => loc.location);
      const center = computeGeometricMedian(points);
      const maxDistance = Math.max(
        ...points.map((loc) =>
          window.google.maps.geometry.spherical.computeDistanceBetween(
            center,
            loc
          )
        )
      );
      return {
        locations,
        center,
        radius: maxDistance,
        totalDistance: computeTotalDistance(points),
      };
    });

    // Sort areas by radius (ascending)
    areas.sort((a, b) => a.radius - b.radius);

    // Select top N areas without overlapping more than 40%
    const topAreas = [];
    areas.forEach((area) => {
      if (topAreas.length >= topN) return; // Limit to topN areas

      let isOverlapping = false;
      for (let selectedArea of topAreas) {
        const overlapPercentage = calculateOverlapPercentage(
          area.center,
          area.radius,
          selectedArea.center,
          selectedArea.radius
        );
        if (overlapPercentage > 40) {
          isOverlapping = true;
          break;
        }
      }

      if (!isOverlapping) {
        // Add circle bounds for adjusting the map view
        const circle = new window.google.maps.Circle({
          center: area.center,
          radius: area.radius,
        });
        area.circleBounds = circle.getBounds();

        topAreas.push(area);
      }
    });

    return topAreas;
  };

  // Helper function to compute the cartesian product of arrays
  const cartesianProduct = (arrays) => {
    return arrays.reduce(
      (acc, curr) => acc.flatMap((a) => curr.map((b) => [...a, b])),
      [[]]
    );
  };

  // Helper function to compute total distance between all points
  const computeTotalDistance = (locations) => {
    let totalDistance = 0;
    for (let i = 0; i < locations.length; i++) {
      for (let j = i + 1; j < locations.length; j++) {
        totalDistance +=
          window.google.maps.geometry.spherical.computeDistanceBetween(
            locations[i],
            locations[j]
          );
      }
    }
    return totalDistance;
  };

  // Helper function to compute the geometric median (using average as approximation)
  const computeGeometricMedian = (locations) => {
    const avgLat =
      locations.reduce((sum, loc) => sum + loc.lat(), 0) / locations.length;
    const avgLng =
      locations.reduce((sum, loc) => sum + loc.lng(), 0) / locations.length;
    return new window.google.maps.LatLng(avgLat, avgLng);
  };

  // Helper function to calculate overlap percentage between two circles
  const calculateOverlapPercentage = (center1, radius1, center2, radius2) => {
    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      center1,
      center2
    );

    // No overlap
    if (distance >= radius1 + radius2) return 0;

    // One circle is completely inside the other
    if (distance <= Math.abs(radius1 - radius2)) {
      const smallerRadius = Math.min(radius1, radius2);
      const overlapArea = Math.PI * Math.pow(smallerRadius, 2);
      const totalArea = Math.PI * Math.pow(smallerRadius, 2);
      return (overlapArea / totalArea) * 100; // 100%
    }

    // Partial overlap
    const r1Sq = radius1 * radius1;
    const r2Sq = radius2 * radius2;

    const alpha = 2 * Math.acos(
      (distance * distance + r1Sq - r2Sq) / (2 * distance * radius1)
    );
    const beta = 2 * Math.acos(
      (distance * distance + r2Sq - r1Sq) / (2 * distance * radius2)
    );

    const area1 = 0.5 * r1Sq * (alpha - Math.sin(alpha));
    const area2 = 0.5 * r2Sq * (beta - Math.sin(beta));

    const overlapArea = area1 + area2;

    // Calculate the area of the smaller circle
    const smallerArea = Math.PI * Math.pow(Math.min(radius1, radius2), 2);

    // Return overlap percentage relative to the smaller circle
    return (overlapArea / smallerArea) * 100;
  };

  if (loadError) {
    console.error('Error loading Google Maps script:', loadError);
    return (
      <Alert variant="danger">
        Error loading Google Maps. Please try again later.
      </Alert>
    );
  }

  return (
    <>
      {!isLoaded ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={(map) => {
              onLoad(map);
            }}
            ref={mapRef}
          >
            {/* Address Search Autocomplete */}
            <Autocomplete
              onLoad={onAutocompleteLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <InputGroup
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-120px',
                  top: '10px',
                  zIndex: 1000,
                  width: '240px',
                }}
              >
                <FormControl
                  type="text"
                  placeholder="Search for an address"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
                {searchInput && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      setSearchInput('');
                      if (searchMarker) {
                        searchMarker.setMap(null);
                        setSearchMarker(null);
                      }
                    }}
                  >
                    X
                  </button>
                )}
              </InputGroup>
            </Autocomplete>

            {/* LayersManager handles predefined layers */}
            <LayersManager
              mapRef={mapRef}
              isLoaded={isLoaded}
              selectedLayers={selectedLayers}
              availableLayers={availableLayers}
              busStopTypeFilters={busStopTypeFilters}
              roadHierarchyTypeFilters={roadHierarchyTypeFilters}
              setLoadingLayers={setLoadingLayers}
              setLayerErrors={setLayerErrors}
              transformDataToGeoJSON={transformDataToGeoJSON}
              fetchedDataCache={fetchedDataCache}
            />

            {/* DrawingManagerComponent handles drawing custom overlays */}
            <DrawingManagerComponent
              mapRef={mapRef}
              isDrawingMode={isDrawingMode}
              setIsDrawingMode={setIsDrawingMode}
              customLayers={customLayers}
              setCustomLayers={setCustomLayers}
            />

            {/* CustomLayersManager manages custom layers */}
            <CustomLayersManager
              mapRef={mapRef}
              customLayers={customLayers}
              setCustomLayers={setCustomLayers}
            />
          </GoogleMap>
        </>
      )}
    </>
  );
};

MapComponent.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  loadError: PropTypes.object,
  onLoad: PropTypes.func.isRequired,
  selectedLayers: PropTypes.object.isRequired,
  availableLayers: PropTypes.object.isRequired,
  busStopTypeFilters: PropTypes.array.isRequired,
  roadHierarchyTypeFilters: PropTypes.array.isRequired,
  setLoadingLayers: PropTypes.func.isRequired,
  setLayerErrors: PropTypes.func.isRequired,
  transformDataToGeoJSON: PropTypes.func.isRequired,
  customLayers: PropTypes.array.isRequired,
  setCustomLayers: PropTypes.func.isRequired,
  isDrawingMode: PropTypes.bool.isRequired,
  setIsDrawingMode: PropTypes.func.isRequired,
  fetchedDataCache: PropTypes.object.isRequired,
  selectedServices: PropTypes.array.isRequired,
  serviceRatings: PropTypes.object.isRequired,
  serviceSearchTrigger: PropTypes.number.isRequired,
  setBestAreas: PropTypes.func.isRequired,
  mapRef: PropTypes.object.isRequired,
  showOtherMarkers: PropTypes.bool.isRequired,
};

export default React.memo(MapComponent);
